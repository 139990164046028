import * as Matter from "matter-js";
// import Swup from "swup";

function playGlockenspiel() {
let Engine = Matter.Engine;
let Render = Matter.Render;
let Runner = Matter.Runner;
// let Matter.Body;
let World = Matter.World;
let Mouse = Matter.Mouse;
// let Matter.Bodies;
let Events = Matter.Events;
let Query = Matter.Query;
let Bounds = Matter.Bounds;
    // let Collision = Matter.Collision;
let Constraint = Matter.Constraint;
let Composite = Matter.Composite;
let MouseConstraint = Matter.MouseConstraint;

// VARIABLES THAT CHANGE BASED ON SCREEN SIZE
let matter_scale = 0.993;
let line_width = 0.18;
let padding = 1;
let height_scale = 1;
let gravity = 2;

let windowWidth = window.innerWidth;
let windowHeight = window.innerHeight;
let vh = windowHeight / 100;
let vw = windowWidth / 100;
// let x = 0;
let mappedX = 0;
let i;
let x = mappedX;
let totalWidth = 0;
let maxItemHeight = 0;
let constraintPointY = 0;

let canv = document.querySelector("canvas");
canv.getContext("2d");

let json = null;
let keys = null;
let randomIndex = null;
let randomKey = null;
let items = null;
let key = null;

const hero = document.getElementById("matter_header");





// What about when the user resizes the window without reloading the page?
if (window.matchMedia("(max-width: 768px)").matches) {
    // mobile-ish
    // matter_scale = 1.8;
    matter_scale = 2.6;
    line_width = 0.7; // 07
    padding = 2.5;
    // height_scale = 1.15; // 1.1
    height_scale = 1.1; // 1.1
    // gravity = 0;
    // gravity = -10;
    
} else {
    // desktop-ish
    // matter_scale = 0.993;
    matter_scale = 1.125;
    line_width = 0.06; // 0.18;
    padding = 1;
    // height_scale = 1.2;
    height_scale = 1.1;
    
    // gravity = -10;
}


let engine = Engine.create();
let runner = Runner.create();
let world = engine.world;

engine.events = {};
World.clear(engine.world);
Engine.clear(engine);

engine = Engine.create();
world = engine.world;

engine.world.gravity.y = gravity;

// Create a renderer
let render = Render.create({
    canvas: canv,
    engine: engine,
    options: {
        width: windowWidth,
        height: 100 * vh,
        pixelRatio: "auto",
        showAngleIndicator: false,
        wireframes: false,
        background: "transparent"
    }
});

Engine.run(engine);
Render.run(render);

let matter__running = true;
let remember__resize = false;






// Set a flag variable to track whether sound is enabled or not
let soundEnabled = false;
const soundOnButton = document.getElementById("soundOn");
soundOnButton.addEventListener("click", function() {
    // Set the soundEnabled flag to true when the button is clicked
    soundEnabled = !soundEnabled;
    if (soundEnabled) {
        soundOnButton.innerHTML = "Glockenspiel: ON";
    } else {
        soundOnButton.innerHTML = "Glockenspiel: OFF";
    }
});

function handleClick() {
    soundEnabled = true;
    if (soundEnabled) {
        soundOnButton.innerHTML = "Glockenspiel: ON";
    } else {
        soundOnButton.innerHTML = "Glockenspiel: OFF";
    }
    canv.removeEventListener("click", handleClick);
}

canv.addEventListener("click", handleClick);








// IIFE SCOPE STARTS HERE
(async function() {
        
    // get json data from script tag in html
    const json = JSON.parse(document.getElementById("matter_data").innerHTML);

    // Get an array of the keys in the JSON object
    const keys = Object.keys(json);    

    // Generate a random number between 0 and the number of keys
    const randomIndex = Math.floor(Math.random() * keys.length);

    // Pick a random key from the array
    const randomKey = keys[randomIndex];
    // Extract the array of objects for the random key
    const items = json[randomKey];
    const key = keys[randomIndex]; // picked random key





    // // Initialize the loading percentage to 0
    // let loadingPercentage = 0;

    // // Function to update loading animation status and hide preloader
    // function updateLoadingStatus(percentage) {
    //     // Update the loading animation to display the percentage
    //     const loadingAnimation = document.getElementById('loading_percentage');
    //     loadingAnimation.textContent = `${percentage}%`;

    //     // Hide the preloader animation when 100% is reached
    //     // if (percentage === 100) {
    //     //     hidePreloader();
    //     // }
    // }   




    // const withLoadingBar = (promises, renderFn, doneMessage) => {
    //     let count = 0
    
    //     promises.forEach(promise => promise
    //         .then(() =>
    //             renderFn(`Loading ${++count} of ${promises.length}`)))
    
    //     const all = Promise.all(promises)
    
    //     all.then(() => renderFn(doneMessage))
    
    //     return all
    // }
    
    // (async() => {
    //     const max = 5
    
    //     const promises = [...new Array(max)]
    //         .map((_, i) => resolveToVal(i))
    
    //     const result = await withLoadingBar(promises, console.warn, 'Done!')
    
    //     console.log(result)
    // })()
  
    // const resolveToVal = val => new Promise(res => 
    //     setTimeout(() => res(val), Math.random() * 1000))
    
    // (async() => {
    //     const max = 5
    //     let count = 0
    
    //     const promises = [...new Array(max)]
    //         .map((_, i) => resolveToVal(i)
    //             .then(() => loadingBarStatus(++count, max)))
    
    //     const result = await Promise.all(promises)
    
    //     console.log(result) // [undefined, undefined, undefined, undefined, undefined]
    // })()







    // Function to load images
    function loadImage(src) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                resolve(img);
                // console.log("image loaded");
            };
            img.onerror = reject;
            img.src = src;
        });
    }

    // Array to store promises for loaded images
    const imagePromises = [];



// Initialize the layout
function init() {

    // Calculate the total width of all items in the array
    let totalWidth = 0;
    let maxItemHeight = 0;

    for (let i = 0; i < items.length; i++) {
        // totalWidth += items[i].matter_w * matter_scale * vw;
        let height = Number(items[i].matter_h);
        let width = Number(items[i].matter_w);

        totalWidth += width * vw * matter_scale;
        maxItemHeight = Math.max(maxItemHeight, (height + width / 2) * vw * matter_scale) / height_scale; 
    }

    // Calculate the starting x position for the first item
    let x = (windowWidth / totalWidth) + padding * vw * matter_scale; //(windowWidth - totalWidth) / 2;
    let y = 0;
    let constraintPointY = 0;





        // if (window.matchMedia("(max-width: 768px)").matches) {

        //     const wallThickness = 50
        //     // const bottomWallLength = windowWidth + wallThickness;
        //     const walls = Composite.create();
        
        //         Composite.add(walls, [
        //             Matter.Bodies.rectangle(
        //                 windowWidth / 2, 
        //                 -10 * vh,
        //                 windowWidth, 
        //                 wallThickness, 
        //                 {isStatic: true} // top
        //             ),
        
        //             Matter.Bodies.rectangle(
        //                 windowWidth + 10 * vw,
        //                 windowHeight / 2 + 10 * vh,
        //                 wallThickness,
        //                 windowHeight * 2,
        //                 {isStatic: true} // right
        //             ),
        
        //             Matter.Bodies.rectangle(  
        //                 -10 * vh,
        //                 windowHeight / 2 + 10 * vh,
        //                 wallThickness, 
        //                 windowHeight * 2,
        //                 {isStatic: true} // left
        //             )
        //         ]);
        
        //     // Add walls composite to world
        //     World.add(engine.world, walls);
        // }


    // Loop over the array of objects
    // Load all images and store promises in imagePromises
    for (let i = 0; i < items.length; i++) {
        // Only create the items and constraints if the key matches the random key
        if (key === randomKey) {
            // Extract the values from the JSON object and store them in an object
            
            y = items[i].matter_y * vw || 0;

            let data = {
                xAdjust: items[i].matter_x_adjust * vw || 0,
                x: x,
                y: items[i].matter_y * vw || 0,
                w: items[i].matter_w * vw * matter_scale,
                h: items[i].matter_h * vw * matter_scale,
                id: items[i].matter_id,
                name: items[i].matter_name, // remove this in json just the slug same as id
                linkTo: items[i].matter_linkto, // remove this json just the slug same as id
                textureUrl: items[i].matter_textureUrl,
                constraintPointX: 0,
                constraintPointY: 0,
                constraintlength: items[i].matter_constraintlength,
                density: 2,
                frictionAir: 0.01,
                xScale: 0.06 * vw * matter_scale,
                yScale: 0.06 * vw * matter_scale
            };

            const promise = loadImage(data.textureUrl);
            imagePromises.push(promise);
            
            // console.log(imagePromises.length);
            // Check when each individual image loads

            imagePromises.forEach(promise => {
                promise.then(() => {
                  // handle individual image load 
                //   console.log("image loaded");
                }); 
              });

            


            // Create the Matter.js item using the extracted data (if I want to adjust the current item xpos i can do it like so "data.x + data.xAdjust" instead of data.x)
            let item = Matter.Bodies.rectangle(data.x, data.y, data.w, data.h, {
                id: data.id,
                density: data.density,
                frictionAir: data.frictionAir,
                render: {
                    sprite: {
                        texture: data.textureUrl,
                        xScale: data.xScale,
                        yScale: data.yScale
                    }
                },
                url: data.linkTo
            });


            // Add the item to the world object after all images have been loaded
            Promise.all(imagePromises)
            .then(() => {

                // hidePreloader();
                // Add the item to the world object after all images have been loaded
                Matter.World.add(world, item);
                // Add the constraints to the world object
                World.add(world, Constraint.create({
                    pointA: {
                        // x: (data.w / data.h * 100) + x,
                        x: data.w / 2 + x,
                        // y: 100 * vh //constraintPointY // 0 
                        y: 0
                    }, 
                    pointB: {
                        x: 0, // - data.w / 2, //randomPointB,
                        // x: - data.w / 2,
                        y: - data.h / 2
                    },
                    // this is where the constraint is attached to the body and if I want to enable the constraint to be attached to the center of the body I can do it like so "pointB: { x: 0, y: 0 }"
                    bodyB: item,
                    // length: data.constraintlength * vw * matter_scale,
                    length: constraintPointY + data.constraintlength * vw * matter_scale,
                    stiffness: 0.1, // Rubber band effect 
                    render: {
                        lineWidth: line_width * vw,
                        type: "line",
                        strokeStyle: "#000",
                        anchors: false,
                        damping: 3
                    }
                }));

                x += data.w + padding * vw; // this works

                // If next item exceeds window width, 
                // reset x and increment y
                if (data.w / 2 + x > windowWidth) {
                    x = padding * vw;
                    // y = 1000 * vw;
                    // console.log("y: " + y);
                    constraintPointY += maxItemHeight; // this is an issue on mobile for some reason. // TODO: fix this 
                }
                // // Break the loop after loading 4 items on mobile 
                // if (window.innerWidth <= 768 && i >= 3) {
                //     break;
                // }

                // updateLoadingStatus(100);

            })
            .catch(error => {
            console.error("Error loading images:", error);
            // updateLoadingStatus('Oops!');
        });








        } // if (key === randomKey) ends here
    } // for loop json ends here


    // if (window.matchMedia("(max-width: 768px)").matches) {


    // Glockenspiel
    const audioUrls = [
        'assets/audio/xylophone/c.mp3', 
        'assets/audio/xylophone/d.mp3', 
        'assets/audio/xylophone/e.mp3', 
        'assets/audio/xylophone/f.mp3',
        'assets/audio/xylophone/g.mp3', 
        'assets/audio/xylophone/a.mp3',
        'assets/audio/xylophone/b.mp3',
        'assets/audio/xylophone/c2.mp3', 
    ];

    // initialize an index variable to keep track of which note to play
    // let audioIndex = 0;
    // initialize an array of AudioBuffers
    const audioBuffers = [];
    // create a new AudioContext
    const audioContext = new AudioContext();
    // create a Matter.js collision event listener
    Events.on(engine, 'collisionStart', function(event) {
        if (soundEnabled) {
            
            // Get the `div` element where you want to display the count
            // var countDiv = document.querySelector('.count');
            // countDiv.innerHTML = collisionCount;

                // Get random index
                const randomIndex = Math.floor(Math.random() * audioUrls.length);
                
                // Get the audio url using the random index (if playing random notes)
                const audioUrl = audioUrls[randomIndex];

                // get the current audio file URL (if playing a song and not random notes)
                // const audioUrl = audioUrls[audioIndex];
            

            // check if the AudioBuffer for this URL has already been loaded
            let audioBuffer = audioBuffers[audioUrl];
            if (!audioBuffer) {
                // if not, load the AudioBuffer asynchronously
                fetch(audioUrl)
                .then(response => response.arrayBuffer())
                .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
                .then(buffer => {
                    // store the AudioBuffer in the array
                    audioBuffers[audioUrl] = buffer;

                    // play the AudioBuffer
                    playAudioBuffer(buffer);
                });
            } else {
                // if the AudioBuffer has already been loaded, play it
                playAudioBuffer(audioBuffer);
            }

            // // increment the audio index and reset it if necessary
            // audioIndex++;
            // if (audioIndex >= audioUrls.length) {
            //     audioIndex = 0;
            // }

        }


    });

    // this function plays the AudioBuffer
    function playAudioBuffer(audioBuffer) {
        // create a new AudioBufferSourceNode
        const source = audioContext.createBufferSource();

        // set the AudioBuffer for the source
        source.buffer = audioBuffer;

    // create a gain node
    const gainNode = audioContext.createGain();

    // set the gain (volume) to a lower value
    // gainNode.gain.value = 0.02; 0.07? idk
    gainNode.gain.value = 0.1;
    
    // connect the source to the gain node
    source.connect(gainNode);
    
    // connect the gain node to the audio context destination
    gainNode.connect(audioContext.destination);
    
    // start playing the source
    source.start();
    }





    // MOUSE CONTROLS (needs to be re-initialized when the window is resized)
    var mouseXO,
        mouseYO,
        mouseXN,
        mouseYN;
    let mouse = Mouse.create(render.canvas),
        mouseConstraint = MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: { // stiffness: 0.2,
                render: {
                    visible: false
                }
            }
        });
    World.add(world, mouseConstraint);


    // Add event with 'mousemove'
    Events.on(mouseConstraint, 'mousemove', function (event) {
        var bodies = engine.world.bodies;
        var foundPhysics = Matter.Query.point(bodies, event.mouse.position);
        if (foundPhysics[0]) {
            hero.classList.add("is-draggable");
        } else {
            hero.classList.remove("is-draggable");
        }
    });

    // Add event with 'mousedown'
    Events.on(mouseConstraint, "mousedown", function (event) {
        mouseXO = event.mouse.absolute.x;
        mouseYO = event.mouse.absolute.y;

        var bodies = engine.world.bodies;
        var foundPhysics = Matter.Query.point(bodies, event.mouse.position);
        // foundPhysics[0]); //returns a shape corresponding to the mouse position
        if (foundPhysics[0]) {
            hero.classList.add("is-dragging");
        }
    });

                                    // Add event with 'mouseup' and check for clicks
                                    Events.on(mouseConstraint, "mouseup", function (event) {
                                        mouseXN = event.mouse.absolute.x;
                                        mouseYN = event.mouse.absolute.y;

                                        hero.classList.remove("is-dragging");
                                        var mouseConstraint = event.source;

                                        if (mouseXO == mouseXN && mouseYO == mouseYN) {
                                            var bodies = engine.world.bodies;
                                            if (! mouseConstraint.bodyB) {
                                                for (i = 0; i < bodies.length; i ++) {
                                                    var body = bodies[i];
                                                    if (Bounds.contains(body.bounds, mouseConstraint.mouse.position)) {
                                                        var bodyUrl = body.url;
                                                        // Go to the #url
                                                        if (bodyUrl != undefined) {
                                                            swup.navigate(bodyUrl);
                                                            Alpine.store('viewsStore').setView('work');
                                                            // window.open(bodyUrl, "_self");
                                                            // SWUPPPP GOTOURL
                                                            // console.log("OK!?!?!?!");
                                                            // Alpine.store('viewsStore').setView('work');
                                                            console.log(bodyUrl)
                                                        }
                                                        break;
                                                    }
                                                }
                                            }
                                        }
                                    });

    // Fixes scrolling in Matter.js 
    mouseConstraint.mouse.element.removeEventListener("mousewheel", mouseConstraint.mouse.mousewheel);
    mouseConstraint.mouse.element.removeEventListener("DOMMouseScroll", mouseConstraint.mouse.mousewheel);

    // Fixes scrolling in Matter.js on mobile
    mouseConstraint.mouse.element.removeEventListener('touchstart', mouseConstraint.mouse.mousedown);
    mouseConstraint.mouse.element.removeEventListener('touchmove', mouseConstraint.mouse.mousemove);
    mouseConstraint.mouse.element.removeEventListener('touchend', mouseConstraint.mouse.mouseup);

    mouseConstraint.mouse.element.addEventListener('touchstart', mouseConstraint.mouse.mousedown, { passive: true });
    mouseConstraint.mouse.element.addEventListener('touchmove', (e) => {
    if (mouseConstraint.body) {
        mouseConstraint.mouse.mousemove(e);
    }
    });
    mouseConstraint.mouse.element.addEventListener('touchend', (e) => {
    if (mouseConstraint.body) {
        mouseConstraint.mouse.mouseup(e);
    }
    });


//     // Function to update the "gravity" variable based on scroll position
// function updateGravity() {
//     const scrollPercentage = hero.scrollTop / (hero.scrollHeight - hero.clientHeight);
//     gravity = (scrollPercentage * 20) - 10; // Map scroll position to a range of -10 to 10
//     // You can use the "gravity" value for further animations or calculations here
// }

// // Listen for the scroll event on the container
// hero.addEventListener("scroll", updateGravity);

// // Initial call to set the initial value
// updateGravity();

























}


// Call init when ready
await init();





// navigator.mediaDevices
//   .getUserMedia({ audio: true })
//   .then(function (stream) {
//     // Access microphone audio stream here
//     const audioContext = new AudioContext();
//     const analyser = audioContext.createAnalyser();
//     const microphoneInput = audioContext.createMediaStreamSource(stream);
//     microphoneInput.connect(analyser);
    
//     // Set up analyser parameters
//     analyser.fftSize = 256;
//     const dataArray = new Uint8Array(analyser.frequencyBinCount);
    
//     // Analyze audio data
//     analyser.getByteFrequencyData(dataArray);
//     console.log(dataArray);

//   })
//   .catch(function (err) {
//     console.error('Error accessing microphone:', err);
//   });




// Get audio stream from microphone // TODO: do this later

// const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
// const audioContext = new AudioContext();
// const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
// const analyserNode = audioContext.createAnalyser();
// mediaStreamAudioSourceNode.connect(analyserNode);

// const pcmData = new Float32Array(analyserNode.fftSize);

// // Map audio data to X position
// const audioValue = dataArray[0]; // Use an appropriate index
// const mappedX = map(audioValue, 0, 255, xMin, xMax);

// // // Update the X position of your Matter.js item
// // item.position.x = mappedX;

// const onFrame = () => {
//     analyserNode.getFloatTimeDomainData(pcmData);
//     let sumSquares = 0.0;
//     for (const amplitude of pcmData) { sumSquares += amplitude*amplitude; }
//     console.log(Math.sqrt(sumSquares / pcmData.length));
//     window.requestAnimationFrame(onFrame);
// };
// window.requestAnimationFrame(onFrame);





function pauseMatter() {
    if(matter__running == true) {
    matter__running = false;

    // Set all bodies to static to pause them for real
    world.bodies.forEach(body => {
        body.isStatic = true;
    });
    Runner.stop(runner, engine);
    Render.stop(render);
    console.log("Pausing Matter.js");
    }
}
// Attach pauseMatter to the global window object (looking at you Alpine.js)
window.pauseMatter = pauseMatter;


function resumeMatter() {
    if(matter__running == false) {
    matter__running = true;

    // Set all bodies to static to pause them for real
    world.bodies.forEach(body => {
        body.isStatic = false;
    });
    Runner.run(runner, engine); 
    Render.run(render);
    console.log("Resuming Matter.js");
        if (remember__resize == true) {
            init();
            remember__resize = false;
        }
    }
}
// Attach resumeMatter to the global window object (looking at you Alpine.js)
window.resumeMatter = resumeMatter;


// Create an intersection observer to pause and resume the Matter.js engine
const headerObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // Header has entered viewport
                resumeMatter(); 	
            } else {
                // Header has exited viewport
                pauseMatter();					
            }
        });
    });
headerObserver.observe(document.getElementById("matter_header"));
    

// Debounce function to prevent the setup function from running too many times when the window is resized
function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (! immediate) 
                func.apply(context, args);
            
        };
        var callNow = immediate && ! timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) 
            func.apply(context, args);
        
    };
}


// Resize event

window.addEventListener("resize", debounce(function () {
    
        windowWidth = window.innerWidth;
        windowHeight = window.innerHeight;
        vh = windowHeight / 100;
        vw = windowWidth / 100;

        // Garbage collection
        Matter.World.clear(engine.world); // remove all bodies from engine before re-adding them

        // Reset the canvas width and height
        render.bounds.max.x = windowWidth;
        render.bounds.max.y = windowHeight;
        render.options.width = windowWidth;
        render.options.height = windowHeight;
        render.canvas.width = windowWidth;
        render.canvas.height = windowHeight;
        Matter.Render.setPixelRatio(render, window.devicePixelRatio); // added this

        // Re-init the items and constraints (if Matter.js is running, if not remember to do it later)
        if(matter__running == true) {
            init();
            remember__resize = false;
        }
        else {
            remember__resize = true;
        }

        // What about when the user resizes the window without reloading the page?
        if (window.matchMedia("(max-width: 768px)").matches) {
            // mobile-ish
            matter_scale = 1.8;
            line_width = 0.7;
            padding = 2.5;
            height_scale = 1.1;
        } else {
            // desktop-ish
            matter_scale = 0.993;
            line_width = 0.25;
            padding = 1;
            height_scale = 1.2;
        }

}, 200)); // resize wait interval   








})(); // IIFE SCOPE ENDS HERE

}




export { playGlockenspiel as default };